
let config = {
    // 默认网关
    baseURL: "",
    axios: {
        timeout: 30000, // 默认超时时间，30秒
        emulateHTTP: false,  // 是否需要通过设置X-HTTP-Method-Override头部并且以传统POST方式发送PUT，PATCH和DELETE请求
    },

    // 登录相关配置
    login: {

    },
    // 登录默认开启验证码
    captcha: false,

    // 路由白名单
    routerWhiteList: [],
    // 接口白名单
    apiWhiteList: [],
    // token存储配置
    storageConfig: {
        storages: ["session"] // 默认存储到 sessionStorage; 命名空间不设置, 使用全局配置的
    },
};

export const getConfig = key => {
    if (typeof key === "string") {
        const arr = key.split(".");
        if (arr && arr.length) {
            let data = config;
            arr.forEach(v => {
                if (data && typeof data[v] !== "undefined") {
                    data = data[v];
                } else {
                    data = null;
                }
            });
            return data;
        }
    }
    return config;
};
export default config
