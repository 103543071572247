/**
 * 页面跳转工具
 */

const base = process.env.NODE_ENV === 'development'
    ? '.'
    : '.'

const navigate = {
    // 普通跳转
    to(page, params = {}) {
        const query = new URLSearchParams(params).toString()


        window.location.href = query
            ? `${base}/${page}.html?${query}`
            : `${base}/${page}.html`
    },

    // 替换当前页面
    replace(page, params = {}) {
        const query = new URLSearchParams(params).toString()
        window.location.replace(query ? `${base}/${page}.html?${query}` : `${base}/${page}.html`)
    },

    // 新窗口打开
    open(page, params = {}) {
        const query = new URLSearchParams(params).toString()
        window.open(query ? `${base}/${page}.html?${query}` : `${base}/${page}.html`)
    }
}

export default navigate
/**
 * 使用方法
 * navigate.to('admin', { id: 1, type: 'user' })
 */