<template>
  <div class="header">
    <div class="wrap">
      <div class="logo">
        <img class="logo-img" alt="云脉SCRM" src="@/assets/logo.png" />
        <h1 class="logo-title">云脉SCRM</h1>
      </div>
      <div class="nav">
        <div
          :class="['item', active === i ? 'active' : '']"
          v-for="(item, i) in menus"
          :key="item.label"
          @click="goto(item)"
        >
          {{ item.label }}
        </div>
      </div>
      <div class="user">
        <div class="islogin" v-if="hasLogin">
          <!-- <div class="avatar"></div> -->
          <!-- <div class="username">
            <div class="name">{{ username }}</div>
            <div class="company">{{ company }}</div>
          </div> -->
          <div class="logout" @click="logout">
            <!-- <img class="logout-img" alt="退出" src="@/assets/logout.png" /> -->
            退出
          </div>
        </div>
        <div class="nologin" @click="login" v-else>登录</div>
      </div>
    </div>
  </div>
</template>


<script>
import { getToken } from "@/utils/auth";
export default {
  components: {},
  props: {
    active: Number,
  },
  data() {
    return {
      // active: 0,
      menus: [
        { label: "首页", url: "index" },
        { label: "产品功能", url: "product" },
        { label: "AI数字员工", url: "aiStaff" },
        { label: "下载中心", url: "download" },
        { label: "产品定价", url: "price" },
        { label: "推广返佣", url: "promotion" },
        { label: "帮助中心", url: "help" },
      ],
      hasLogin: false,
      username: "匿名",
      company: "匿名公司",
    };
  },
  created() {
    // let token = getToken() ?? {};
    // // console.log(token);
    // if (!token?.userinfo?.username) return;
    // this.username = token?.userinfo?.username;
    // this.company = token?.userinfo?.company_name;
  },
  methods: {
    goto(item) {
      this.$emit("changeActive", item.url);
      if (item.url === "product") {
        window.location.href = "./index.html#product";
        return;
      }
      this.$nav.to(item.url);
    },
    login() {
      this.hasLogin = true;
    },
    logout() {
      this.hasLogin = false;
      // this.$store.dispatch("FedLogOut");
    },
  },
};
</script>

<style scoped lang="scss">
// @import "@/styles/variables.scss";
.header {
  height: $header-height;
  background: #fff;
  // background: #EEF6FF;
  box-shadow: 0px -10px 34px 0px #ffffff66 inset;

  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Alibaba PuHuiTi 2";
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  width: 100%;

  .wrap {
    display: flex;
    height: 40px;
  }

  .logo {
    flex: 0 0 190px;
    height: 100%;
    font-family: "Alibaba PuHuiTi 700";
    font-weight: 700;
    font-size: 28px;
    display: flex;
    align-items: center;

    .logo-img {
      width: 40px;
      height: 40px;
    }

    .logo-title {
      margin-left: 10px;
      font-size: 18px;
      font-weight: 700;
    }
  }

  .nav {
    height: 100%;
    flex: 1;
    display: flex;
    // margin-left: 60px;
    font-weight: 500;
    font-size: 18px;

    .item {
      margin-right: 40px;
      // padding: 0 5px;
      height: 100%;
      display: flex;
      align-items: center;
      color: #333333;
      text-wrap: nowrap;
      cursor: pointer;
      position: relative;

      &:after {
        content: "";
        display: block;
        position: absolute;
        border-bottom: 2px solid $primary-color;
        bottom: 0;
        left: 0;
        height: 4px;
        width: 0;
        transition: width 0.4s;
      }

      &.active {
        color: $primary-color;

        &:after {
          content: "";
          display: block;
          position: absolute;
          border-bottom: 2px solid $primary-color;
          bottom: 0;
          left: 0;
          height: 4px;
          width: 100%;
        }
      }

      &:hover {
        color: $primary-color;

        &:after {
          width: 100%;
        }
      }
    }
  }

  .user {
    // cursor: pointer;
    // flex: 0 0 600px;
    display: flex;
    align-items: center;
    justify-content: center;

    .nologin {
      width: 76px;
      height: 36px;
      border-radius: 4px;
      background: #0079f9;
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: Alibaba PuHuiTi 2;
      font-size: 14px;
      font-weight: 400;
      line-height: 25.2px;
      text-align: left;
      color: #ffffff;
      cursor: pointer;
    }

    .islogin {
      display: flex;
      align-items: center;

      .avatar {
        width: 48px;
        height: 48px;
        // background: url(~@/assets/avatar.png) no-repeat;
        background-size: 100%;
      }

      .username {
        margin: 0 10px 0 5px;

        .name {
          font-size: 16px;
        }

        .company {
          // border: 1px solid #3070ff;
          max-width: 122px;
          height: 22px;
          // padding: 2px 4px 2px 4px;
          border-radius: 4px;
          font-size: 12px;
          line-height: 20px;
          // text-align: center;
        }
      }

      .logout {
        width: 76px;
        height: 36px;
        border-radius: 4px;
        background: #0079f9;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: Alibaba PuHuiTi 2;
        font-size: 14px;
        font-weight: 400;
        line-height: 25.2px;
        text-align: left;
        color: #ffffff;
        cursor: pointer;
        .logout-img {
          width: 18px;
          height: 18px;
          margin-right: 5px;
        }
      }
    }
  }
}
</style>
