import { getStorageInstance } from "./storage";


const defaultKey = "x-authorized-token";
const namespace = "xdj";

let TokenKey = defaultKey;

export const setTokenKey = function (key) {
  if (key) {
    TokenKey = key;
  }
};

export const __getTokenKey = function () {
  return TokenKey;
}

export const getToken = function () {
  return getStorageInstance().get(TokenKey, namespace);
};

/**
 * 设置本地token，同时更新 vuex token 及 username
 * @param data
 */
export const setToken = function (data) {
  if (!data) return;
  let { token, userinfo } = data;
  let { access_token, expires_in } = token;
  if (access_token && expires_in) {
    // 前端设置提前 1s 过期
    if (expires_in > 2) {
      expires_in -= 1;
    }
    data.token.expires_in = Date.now() + parseInt(expires_in) * 1000;
  }


  // 使用 token 的配置来存储
  return getStorageInstance().set(
    TokenKey,
    data,
    {
      storages: ["session"] // 默认存储到 sessionStorage; 命名空间不设置, 使用全局配置的
    }
  );
};

export const removeToken = function () {
  return getStorageInstance().remove(TokenKey, namespace);
};

export default {
  setTokenKey,
  getToken,
  setToken,
  removeToken,
};
