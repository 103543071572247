<template>
  <div class="c-footer">
    <div class="box_20 flex-row" v-if="!!msg">
      <img
        class="image_5"
        referrerpolicy="no-referrer"
        src="@/assets/footer/FigmaDDSSlicePNG0b922a5cfa0dd6e1497f0a4b5cd8ec9b.png"
      />
      <div class="section_9">
        <div class="text_25">
          {{msg}}
        </div>
        <div class="box_21 flex-row clickable" @click="start">
          <div class="image-text_4 flex-row justify-between">
            <span class="text-group_9">马上开始</span>
            <img
              class="thumbnail_3"
              referrerpolicy="no-referrer"
              src="@/assets/footer/FigmaDDSSlicePNG93458806c782125006b04b4fa89b5a7b.png"
            />
          </div>
        </div>
      </div>
      <img
        class="image_6"
        referrerpolicy="no-referrer"
        src="@/assets/footer/FigmaDDSSlicePNGd1ffea3f48c1c7464471b5811313f841.png"
      />
    </div>
    <div class="box_22 flex-row">
      <div class="section_10 flex-col">
        <div class="group_2 flex-row justify-between">
          <div class="image-wrapper_8 flex-col">
            <img
              class="label_7"
              referrerpolicy="no-referrer"
              src="@/assets/footer/FigmaDDSSlicePNGb468f4feb324f89088d98bf027b997d2.png"
            />
          </div>
          <span class="text_26">云脉SCRM</span>
          <div class="group_3 flex-col"></div>
          <span class="text_27">由Al驱动的SCRM</span>
        </div>
        <span class="text_28">
          AI驱动的营销解决方案，助您实现全球业务飞跃
        </span>
        <div class="group_4 flex-row clickable"  @click="download">
          <div class="image-text_5 flex-row justify-between">
            <img
              class="label_8"
              referrerpolicy="no-referrer"
              src="@/assets/footer/FigmaDDSSlicePNG1e20ed48c962d85b2063c2130c951d37.png"
            />
            <span class="text-group_10">Windows版下载</span>
          </div>
        </div>
        <div class="group_5 flex-row clickable"  @click="download">
          <div class="image-text_6 flex-row justify-between">
            <img
              class="label_9"
              referrerpolicy="no-referrer"
              src="@/assets/footer/FigmaDDSSlicePNG863766f0a3edff87a905b5dc9f34a329.png"
            />
            <span class="text-group_11">Mac版下载</span>
          </div>
        </div>
      </div>
      <div class="section_11 flex-col">
        <span class="text_29">联系我们</span>
        <span class="paragraph_6">
          Telegram客服
          <br />
          &#64;云脉出海SCRM-X
          <br />
          Telegram官方频道
          <br />
          &#64;云脉出海SCRM
          <br />
          微信客服
          <br />
          <br />
          <br />
          <br />
          联系邮件
          <br />
          support&#64;cvein-ai.com
        </span>
        <img
          class="image_7"
          referrerpolicy="no-referrer"
          src="@/assets/footer/FigmaDDSSlicePNGd733b0e9d86f7459328f5c834768165a.png"
        />
      </div>
      <div class="text-group_12 flex-col justify-between">
        <span class="text_30">解决方案</span>
        <span class="paragraph_7">
          多渠道营销平台整合
          <br />
          个性化营销方案
          <br />
          智能广告投放
          <br />
          自动化客户互动
          <br />
          实时数据分析全启发
        </span>
      </div>
    </div>
    <div class="box_23">
      <div class="wrap flex-row">
        <span class="text_31">
          Copyright&nbsp;©&nbsp;&nbsp;2022&nbsp;2024&nbsp;cvein.cc&nbsp;All&nbsp;Rights&nbsp;Reserved
        </span>
        <span class="text_32">实时信息&nbsp;关注我们</span>
        <img
          class="label_10"
          referrerpolicy="no-referrer"
          src="@/assets/footer/FigmaDDSSlicePNG9822ef7eb5b6696fa92fa715a7e7fa44.png"
        />
        <img
          class="label_11"
          referrerpolicy="no-referrer"
          src="@/assets/footer/FigmaDDSSlicePNGc4a6458500b94db27705bc821dc1ac6b.png"
        />
        <div class="image-wrapper_9 flex-col">
          <img
            class="label_12"
            referrerpolicy="no-referrer"
            src="@/assets/footer/FigmaDDSSlicePNGfeb8180f881381369128eee7daa73163.png"
          />
        </div>
        <img
          class="label_13"
          referrerpolicy="no-referrer"
          src="@/assets/footer/FigmaDDSSlicePNGc6b99b3e2b92557b503bf67a64b5e1ad.png"
        />
        <img
          class="label_14"
          referrerpolicy="no-referrer"
          src="@/assets/footer/FigmaDDSSlicePNG1e919f9fe104a69688445ef3ee4dbd40.png"
        />
        <img
          class="label_15"
          referrerpolicy="no-referrer"
          src="@/assets/footer/FigmaDDSSlicePNG370526caf6acad5b9c94c4666875cfbc.png"
        />
        <div class="image-wrapper_10 flex-col">
          <img
            class="label_16"
            referrerpolicy="no-referrer"
            src="@/assets/footer/FigmaDDSSlicePNG8f180bf90d05b5c35fac19166d1c5446.png"
          />
        </div>
        <img
          class="label_17"
          referrerpolicy="no-referrer"
          src="@/assets/footer/FigmaDDSSlicePNGafab2136cb8219732c0bb8d0e3c16312.png"
        />
        <img
          class="label_18"
          referrerpolicy="no-referrer"
          src="@/assets/footer/FigmaDDSSlicePNGb088f23b74fe926cb2ecdb4f59376644.png"
        />
        <img
          class="label_19"
          referrerpolicy="no-referrer"
          src="@/assets/footer/FigmaDDSSlicePNG972f162cad518ddddcef7c51d8d1ae9a.png"
        />
        <div class="image-wrapper_11 flex-col">
          <img
            class="image_8"
            referrerpolicy="no-referrer"
            src="@/assets/footer/FigmaDDSSlicePNGbd3e7195671bbf5f903a75492078b7eb.png"
          />
        </div>
      </div>
    </div>
  </div>
</template>


<script>
export default {
  components: {},
  props: {
    msg: String,
  },
  data() {
    return {};
  },
  created() {},
  methods: {
    download(){
      this.$nav.to('download')
    },
    start(){
      this.$message('敬请期待')
    }
    
  },
};
</script>

<style scoped lang="scss">
@import "@/styles/lanhu_common.scss";
.c-footer {
  width: 100%;
}
.box_20 {
  background-color: rgba(0, 121, 249, 1);
  width: 100%;
  height: 427px;
  position: relative;
  align-items: center;
  justify-content: center;
}

.image_5 {
  width: 407px;
  height: 420px;
  position: absolute;
  left: 0;
  top: 0;
}
.image_6 {
  width: 397px;
  height: 420px;
  position: absolute;
  right: 0;
  top: 0;
}

.section_9 {
  width: 100%;
  height: 100%;
 
  text-align: center;
}

.text_25 {
  margin-top: 136px;
  width: 100%;
  height: 52px;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 40px;
  font-family: Helvetica, "Microsoft YaHei", Arial, sans-serif;
  font-weight: NaN;
  text-align: center;
  white-space: nowrap;
  // line-height: 129px;
}

.box_21 {
  border-radius: 8px;
  width: 155px;
  height: 52px;
  border: 1px solid rgba(255, 255, 255, 1);
  margin: 48px auto 0;
}

.image-text_4 {
  width: 99px;
  height: 24px;
  margin: 14px 0 0 28px;
}

.text-group_9 {
  width: 71px;
  height: 24px;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 18px;
  font-family: Helvetica, "Microsoft YaHei", Arial, sans-serif;
  font-weight: NaN;
  text-align: center;
  white-space: nowrap;
  line-height: 24px;
}

.thumbnail_3 {
  width: 20px;
  height: 20px;
  margin-top: 2px;
}

.box_22 {
  background-color: rgba(255, 255, 255, 1);
  width: 1400px;
  height: 410px;
  margin: 0 auto;
  margin-bottom: 41px;
}

.section_10 {
  width: 290px;
  height: 264px;
  margin: 34px 0 0 73px;
}

.group_2 {
  width: 290px;
  height: 40px;
}

.image-wrapper_8 {
  background-image: linear-gradient(
    135deg,
    rgba(255, 255, 255, 1) 0,
    rgba(255, 255, 255, 0.1) 100%
  );
  border-radius: 50%;
  height: 40px;
  border: 1px solid rgba(238, 238, 238, 1);
  width: 40px;
}

.label_7 {
  width: 28px;
  height: 25px;
  margin: 8px 0 0 6px;
}

.text_26 {
  width: 84px;
  height: 25px;
  overflow-wrap: break-word;
  color: rgba(51, 51, 51, 1);
  font-size: 18px;
  font-family: Helvetica, "Microsoft YaHei", Arial, sans-serif;
  font-weight: NaN;
  text-align: left;
  white-space: nowrap;
  // line-height: 18px;
  margin: 8px 0 0 10px;
}

.group_3 {
  background-color: rgba(217, 217, 217, 1);
  width: 1px;
  height: 14px;
  margin: 13px 0 0 10px;
}

.text_27 {
  width: 135px;
  height: 25px;
  overflow-wrap: break-word;
  color: rgba(0, 0, 0, 1);
  font-size: 18px;
  font-family: Helvetica, "Microsoft YaHei", Arial, sans-serif;
  font-weight: NaN;
  text-align: left;
  white-space: nowrap;
  // line-height: 18px;
  margin: 7px 0 0 10px;
}

.text_28 {
  width: 287px;
  height: 50px;
  overflow-wrap: break-word;
  color: rgba(51, 51, 51, 1);
  font-size: 18px;
  font-family: Helvetica, "Microsoft YaHei", Arial, sans-serif;
  font-weight: NaN;
  text-align: left;
  margin: 16px 0 0 3px;
}

.group_4 {
  border-radius: 8px;
  width: 290px;
  height: 52px;
  border: 1px solid rgba(51, 51, 51, 1);
  margin-top: 30px;
}

.image-text_5 {
  width: 145px;
  height: 32px;
  margin: 10px 0 0 73px;
}

.label_8 {
  width: 32px;
  height: 32px;
}

.text-group_10 {
  width: 103px;
  height: 20px;
  overflow-wrap: break-word;
  color: rgba(51, 51, 51, 1);
  font-size: 14px;
  letter-spacing: -0.16500000655651093px;
  font-family: Helvetica, "Microsoft YaHei", Arial, sans-serif;
  font-weight: NaN;
  text-align: left;
  white-space: nowrap;
  // line-height: 14px;
  margin-top: 6px;
}

.group_5 {
  border-radius: 8px;
  width: 290px;
  height: 52px;
  border: 1px solid rgba(51, 51, 51, 1);
  margin-top: 24px;
}

.image-text_6 {
  width: 145px;
  height: 32px;
  margin: 10px 0 0 73px;
}

.label_9 {
  width: 32px;
  height: 32px;
}

.text-group_11 {
  width: 103px;
  height: 20px;
  overflow-wrap: break-word;
  color: rgba(51, 51, 51, 1);
  font-size: 14px;
  letter-spacing: -0.16500000655651093px;
  font-family: Helvetica, "Microsoft YaHei", Arial, sans-serif;
  font-weight: NaN;
  text-align: left;
  white-space: nowrap;
  // line-height: 14px;
  margin-top: 6px;
}

.section_11 {
  width: 171px;
  height: 362px;
  margin: 42px 0 0 80px;
}

.text_29 {
  width: 98px;
  height: 28px;
  overflow-wrap: break-word;
  color: rgba(51, 51, 51, 1);
  font-size: 20px;
  letter-spacing: 1px;
  font-family: Helvetica, "Microsoft YaHei", Arial, sans-serif;
  font-weight: NaN;
  text-align: left;
  white-space: nowrap;
  line-height: 20px;
}

.paragraph_6 {
  width: 171px;
  height: 320px;
  overflow-wrap: break-word;
  color: rgba(102, 102, 102, 0.8);
  font-size: 16px;
  font-family: Helvetica, "Microsoft YaHei", Arial, sans-serif;
  font-weight: NaN;
  text-align: left;
  line-height: 32px;
  margin-top: 14px;
}

.image_7 {
  width: 98px;
  height: 98px;
  margin: -160px 0 62px 0;
}

.text-group_12 {
  width: 200px;
  height: 202px;
  margin: 42px 0px 0 292px;
}

.text_30 {
  width: 82px;
  height: 28px;
  overflow-wrap: break-word;
  color: rgba(51, 51, 51, 1);
  font-size: 20px;
  letter-spacing: 1px;
  font-family: Helvetica, "Microsoft YaHei", Arial, sans-serif;
  font-weight: NaN;
  text-align: left;
  white-space: nowrap;
  line-height: 20px;
}

.paragraph_7 {
  // width: 142px;
  height: 160px;
  overflow-wrap: break-word;
  color: rgba(102, 102, 102, 0.8);
  font-size: 16px;
  font-family: Helvetica, "Microsoft YaHei", Arial, sans-serif;
  font-weight: NaN;
  text-align: left;
  line-height: 32px;
  margin-top: 14px;
}

.box_23 {
  background-color: rgba(250, 250, 250, 1);
  width: 100%;
  height: 56px;
  // margin-top: -1px;
}

.text_31 {
  width: 391px;
  height: 20px;
  overflow-wrap: break-word;
  color: rgba(51, 51, 51, 1);
  font-size: 14px;
  letter-spacing: 1px;
  font-family: Helvetica, "Microsoft YaHei", Arial, sans-serif;
  font-weight: NaN;
  text-align: left;
  white-space: nowrap;
  line-height: 14px;
  margin: 18px 0 0 73px;
}

.text_32 {
  width: 122px;
  height: 20px;
  overflow-wrap: break-word;
  color: rgba(51, 51, 51, 1);
  font-size: 14px;
  letter-spacing: 1px;
  font-family: Helvetica, "Microsoft YaHei", Arial, sans-serif;
  font-weight: NaN;
  text-align: left;
  white-space: nowrap;
  line-height: 14px;
  margin: 18px 0 0 353px;
}

.label_10 {
  width: 24px;
  height: 24px;
  margin: 16px 0 0 26px;
}

.label_11 {
  width: 24px;
  height: 24px;
  margin: 16px 0 0 16px;
}

.image-wrapper_9 {
  background-color: rgba(217, 217, 217, 0);
  height: 24px;
  width: 24px;
  margin: 16px 0 0 16px;
}

.label_12 {
  width: 24px;
  height: 23px;
  margin-top: 1px;
}

.label_13 {
  width: 24px;
  height: 24px;
  margin: 16px 0 0 16px;
}

.label_14 {
  width: 24px;
  height: 24px;
  margin: 16px 0 0 16px;
}

.label_15 {
  width: 24px;
  height: 24px;
  margin: 16px 0 0 16px;
}

.image-wrapper_10 {
  background-color: rgba(217, 217, 217, 0);
  height: 24px;
  width: 24px;
  margin: 16px 0 0 16px;
}

.label_16 {
  width: 22px;
  height: 22px;
  margin: 1px 0 0 1px;
}

.label_17 {
  width: 25px;
  height: 25px;
  margin: 16px 0 0 16px;
}

.label_18 {
  width: 25px;
  height: 24px;
  margin: 16px 0 0 16px;
}

.label_19 {
  width: 24px;
  height: 24px;
  margin: 16px 0 0 15px;
}

.image-wrapper_11 {
  background-color: rgba(217, 217, 217, 0);
  height: 24px;
  width: 24px;
  margin: 16px 260px 0 16px;
}

.image_8 {
  width: 24px;
  height: 20px;
  margin-top: 2px;
}
</style>
