/*
 * @Author: yanhao
 * @Date: 2021-06-15 11:37:07
 * @LastEditTime: 2021-06-19 17:18:59
 * @LastEditors: Please set LastEditors
 * @Description: 支持命名空间的存储工具
 * @FilePath: \ve-framework\utils\storage.js
 */
import Basil from "basil.js";
import { getConfig } from "../config";

// 直接 export from 个别项目打包会导致兼容问题
// export * as Basil from "basil.js";

export { Basil };

let _storage;

export const storageInit = function (options) {
  _storage = new Basil(options);
  //   console.log("storageInit...");
};

export const getStorageInstance = function (options) {
  //   console.log("getStorageInstance...", _storage);

  if (_storage) {
    return _storage;
  }
  console.warn("storage 没有正常初始化");
  return new Basil(options);
};

export const clearStorage = function () {
  if (typeof _storage == "object") {
    // 保留缓存 key
    const reservedKeys = ["userRemember", "exit-user-id"];
    const keys = _storage.keys();
    // console.log("clearStorage ------------ ", keys);
    if (Array.isArray(keys)) {
      keys
        .filter(v => !reservedKeys.includes(v))
        .forEach(key => {
          _storage.remove(key);
        });
    }
  }
};


storageInit(getConfig('storageConfig'));

export const get = function (key, namespace) {
  getStorageInstance().get(key, namespace);
};

